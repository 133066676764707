exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beskydske-tury-tsx": () => import("./../../../src/pages/beskydske-tury.tsx" /* webpackChunkName: "component---src-pages-beskydske-tury-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cheatsheets-tsx": () => import("./../../../src/pages/cheatsheets.tsx" /* webpackChunkName: "component---src-pages-cheatsheets-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-notion-tsx": () => import("./../../../src/pages/notion.tsx" /* webpackChunkName: "component---src-pages-notion-tsx" */)
}

